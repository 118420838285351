<template>
  <page-header-wrapper>
    <div style="background-color: white; padding: 2%;display: flex;width: 100%">
      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">用户总数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.userTotalNum }}</font></p>
      </a-card>

      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">消费人数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.consumptionNum }}</font></p>
      </a-card>

      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">未消费人数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.noConsumptionNum }}</font></p>
      </a-card>

      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">订阅总数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.subscriptionNum }}</font></p>
      </a-card>

<!--      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 14%;border-radius: 5%;margin: 0.5%">-->
<!--        <p><font style="font-size: 20px">来源总数</font></p>-->
<!--        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.sourceNum }}</font></p>-->
<!--      </a-card>-->

      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">活跃总数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.activeNum }}</font></p>
      </a-card>

      <a-card  :bordered="false" style="background-color:rgb(236,246,255);width: 16%;border-radius: 5%;margin:0.5%">
        <p><font style="font-size: 20px">沉默总数</font></p>
        <p><font style="font-size: 20px;font-weight: bold">{{ this.userData.silentNum }}</font></p>
      </a-card>


    </div>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="手机号" prop="mobile">-->
<!--                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="邮箱" prop="email">-->
<!--                <a-input v-model="queryParam.email" placeholder="请输入邮箱" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
              <a-col :md="4" :sm="24">
                <a-form-item label="ID" prop="nickname">
                  <a-input v-model="queryParam.showId" placeholder="请输入昵称" allow-clear/>
                </a-form-item>
              </a-col>



              <a-col :md="4" :sm="24">
                <a-form-item label="昵称" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
                </a-form-item>
              </a-col>

              <a-col :md="4" :sm="24">
                <a-form-item label="来源" prop="sex">
                  <a-select placeholder="请选择来源" v-model="queryParam.type" >
                    <a-select-option :value="0">Facebook</a-select-option>
                    <a-select-option :value="1">Google</a-select-option>
                    <a-select-option :value="2">Apple</a-select-option>
                    <a-select-option :value="3">Tiktok</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="4" :sm="24">
                <a-form-item label="是否会员" prop="sex">
                  <a-select placeholder="请选择是否会员" v-model="queryParam.isVip">
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>


              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
            </template>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>


        <span slot="userStatus" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.status == 1" @change="changeIsOpen($event,record)"/>
        </span>


        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="vipOverTime" slot-scope="text, record">
          {{ parseTime(record.vipOverTime) }}
        </span>

        <span slot="operation" slot-scope="text, record">
          <a @click="$router.push({name: 'AccountBill', query:{showId: record.showId}})" >
            <a-icon />用户账单
          </a>

        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser,changeIsOpen,getUserTotalData } from '@/api/biz/userForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      userData: {},
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '三方账号',
        //   dataIndex: 'mobile',
        //   align: 'center'
        // },
        // {
        //   title: '邮箱',
        //   dataIndex: 'email',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '序号',
          // ellipsis: true,,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        {
          title: 'ID',
          dataIndex: 'showId',
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '来源',
          dataIndex: 'type',
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return 'Facebook'
            } else if(t == 1) {
              return 'Google'
            } else if(t == 2) {
              return 'Apple'
            } else if(t == 3) {
              return 'Tiktok'
            }
          }
        },

        {
          title: '地区',
          dataIndex: 'address',
          align: 'center'
        },

        // {
        //   title: '用户状态',
        //   dataIndex: 'status',
        //   scopedSlots: { customRender: 'status' },
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '是否会员',
          dataIndex: 'isVip',
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '否'
            }else if(t == 1) {
              return '是'
            }
          }
        },

        {
          title: '订阅到期时间',
          dataIndex: 'vipOverTime',
          scopedSlots: { customRender: 'vipOverTime' },
          align: 'center'
        },


        {
          title: '累计充值',
          dataIndex: 'totalPay',
          align: 'center'
        },


        {
          title: '钻石余额',
          dataIndex: 'diamond',
          align: 'center'
        },
        {
          title: '活跃度',
          dataIndex: 'isActive',
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '活跃'
            }else{
              return '沉默'
            }
          }
        },

        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'userStatus' },
        },

        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getUserTotalData()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    getUserTotalData() {
      getUserTotalData().then(response => {
        this.userData = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        isVip: undefined,
        invitationCode: undefined,
        showId: undefined,
        type: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.queryParam.pageNum = 1
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },



    changeIsOpen (e,record) {
      record.status = e ? 1 : 0
      changeIsOpen(record).then(response => {
        this.$message.success(
            '操作成功'
        )
        return  record.status = e ? 1 : 0
      })
    },



    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
